import { configureStore } from "@reduxjs/toolkit";
import plateReducer from '../components/Plates/plateSlice';
import productReducer from '../components/Products/productSlice';
import featuredReducer from '../components/Featured/featuredSlice';
import userReducer from "../components/Auth/userSlice";
import modeReducer from "../components/Theme/modeSlice";
import walletReducer from "../components/Wallet/walletSlice";
import subscriptionReducer from "../components/Subscriptions/subscriptionSlice";
import notificationReducer from "../components/Notifications/notificationSlice";
import redirectReducer from "../components/Auth/historySlice";
import diningReducer from "../request/Dining/diningSlice";
import productsReducer from "../request/Products/productsSlice";
import plansReducer from "../request/Plans/planSlice";
import authReducer from "../request/Auth/authSlice";
import referralReducer from "../request/Referrals/referalSlice";
import unitAnalysisReducer from "../components/Analysis/analysisSlice";
import chartsReducer from "../components/Analysis/chartsSlice";
import messageReducer from "../components/Message/messageSlice";
import categoryReducer from "../components/Categories/categorySlice";

export const store = configureStore({
    reducer: {
        plate: plateReducer,
        category: categoryReducer,
        product: productReducer,
        featured: featuredReducer,
        user: userReducer,
        mode: modeReducer,
        wallet: walletReducer,
        subscriptions: subscriptionReducer,
        notifications: notificationReducer,
        redirect: redirectReducer,
        dinings: diningReducer,
        products: productsReducer,
        plans: plansReducer,
        auth: authReducer,
        referrals: referralReducer,
        unitsAnalysis: unitAnalysisReducer,
        chartsAnalysis: chartsReducer,
        messages: messageReducer
    }
})