import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { Button, CardActions } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TodayBreakFast from '../Home/TodayBreakFast';
import TodayDinner from '../Home/TodayDinner';
import TodayLunch from '../Home/TodayLunch';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Plate from '../Plates/Plate';
import DetailsTab from './DetailsTab';
import RelatedProducts from './RelatedProducts';
import ProductImageSlider from './ProductSlider';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Product = () => {
 
  let params = useParams();
  const product = useSelector((state) => state.product.product);

  useEffect(() => {
    document.title = product.name;
  }, [params]);

  return (
    <div style={{marginTop: '80px'}}>
      <Container sx={{padding: 1, marginBottom: '30px', bgcolor: '#F0E4CC' }}>

      <Grid container spacing={2}>
          <Grid xs={12}  item md={6}>
          <ProductImageSlider product={product} images={product.images.split(",")} />

          </Grid>
          <Grid item xs={12} md={6}>
          <Card sx={{bgcolor: '#F0E4CC' }} elevation={0}>                
                <DetailsTab product={product}/>
          </Card>
          </Grid>
        </Grid>
        
        </Container>
        <Divider/>
        <RelatedProducts sample={product}/>
        <TodayBreakFast/>
        <TodayLunch/>
        {/* <TodayDinner/> */}
    </div>
  )
}

export default Product
