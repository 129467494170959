import React from 'react'
import { useDispatch } from 'react-redux';
import { addItem } from './plateSlice';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Timer from './Timer';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Plate = (props) => {
    const {product} = props;
    const dispatch = useDispatch();
      const appMode = useSelector((state) => state.mode.mode);
    
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
      };
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
  };

  return (
    <>
    {parseInt(product.qty) < 1 ?
      <Button disabled sx={{ textTransform: 'capitalize' }} color='error' variant="outlined" startIcon={(parseInt(product.qty)  === 0) ? <NotInterestedIcon /> :  <AccessAlarmIcon/>}>
        {(parseInt(product.qty)  === 0) ? 'Finished' :  <Timer time={product.timer !== null? product.timer : 0 }/>}
      </Button>
     :
     <Avatar onClick={() => dispatch(addItem(product))}
     src={appMode === 'dark' ? 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739184208/cart-white_be57p3.png' : 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739151373/cart_1_w5ytl8.png'} sx={{ padding: '5px', width: 40, height: 40}}>
     </Avatar>
     }
    
        {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {`${product.name} added to plate!`}
        </Alert>
      </Snackbar> */}
    </>
  )
}

export default Plate
