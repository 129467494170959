import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentCategory: localStorage.getItem('currentCategory') || null,
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategory: (state, action) =>{
            state.currentCategory = action.payload;  
            localStorage.setItem('currentCategory', state.currentCategory);
        }       
    }
})

export const {setCategory} = categorySlice.actions;
export default categorySlice.reducer;