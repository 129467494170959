import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

import { Link as RouterLink } from 'react-router-dom';
import { CardActions, Link, Paper } from '@mui/material';
import { setFeatured } from '../Featured/featuredSlice';
import { useDispatch , useSelector} from 'react-redux';
import { setProduct } from '../Products/productSlice';
import Plate from '../Plates/Plate';

const PackageSlides = () => {
  const dispatch = useDispatch();
  const scrolltoTop = () => window.scrollTo(0, 0);
  const featureds = useSelector((state) => state.featured.featureds);

    const settings = {
        autoplay: true,
        pauseOnHover: true,
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      };


  return (
    <Box>
      <Paper  sx={{bgcolor: '#F5F5DC', marginTop: '5px'}}>
        <Typography className='new-text' sx={{fontWeight: 'bold', textTransform: 'capitalize'  }} gutterBottom variant="h4">
        BRAND NEW ITEMS
        </Typography> 
      </Paper>
        <Card sx={{paddingX: '10px', marginTop: '10px'}} elevation={0}>

{/* <Typography sx={{marginLeft: '5px', fontWeight: 'bold', textTransform: 'capitalize', fontSize: 20, textAlign: 'left'  }} gutterBottom variant="h6" component="div">
        Most Popular Products
    </Typography> */}

          {featureds.length > 0 &&
          <Slider {...settings}>
          {featureds.map(featured=>(
        <Link key={featured.product.id} onClick={()=> {dispatch(setProduct(featured.product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
              <Card sx={{marginX: '5px', borderRadius: '5px', bgcolor: '#F0E4CC'}}>
                  <CardMedia
                          component="img"
                          sx={{ height: '100px' }}
                          image={featured.product.product_image}
                          alt={featured.product.name}
                      />
                      <CardActions justifyContent='space-between' sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                        <Typography  variant="h6" color='secondary' >
                          &#8358;{featured.product.unit_price}
                        </Typography>
                        </Box>
                        <Box>
                        <Plate product={featured.product} />
                        </Box>
                      </CardActions>
                      
              </Card>
          </Link>
          ))}
          
        </Slider>
          }
      
      </Card>
      </Box>
  )
}

export default PackageSlides
