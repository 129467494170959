import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Plate from '../Plates/Plate';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setProduct } from '../Products/productSlice';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import MainCategory from '../Home/MainCategory';
const CategoryProducts = () => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };
    const scrolltoTop = () => window.scrollTo(0, 0);
    const dispatch = useDispatch();
    const params = useParams();

    const [products, setproducts] = useState([]);
  const categories = useSelector((state) => state.products.categories);

    useEffect(() => {
      const items = JSON.parse(localStorage.getItem('products'));
      if (items) {
        setproducts(items);
      }

      window.addEventListener('updatedStorage', function (e) {
        const items = JSON.parse(localStorage.getItem('products'));
        if (items) {
          setproducts(items);
        }
    }, false); 
    

    }, [params]);

  return (
    <div style={{marginTop: '70px', backgroundColor: '#F0E4CC', paddingBottom: '5px'}}>
      <MainCategory/>
    {/* <Typography mt={4} sx={{  textTransform: 'uppercase', textAlign: 'center'  }} gutterBottom variant="h4">
        {params.category}
    </Typography> */}

    <Paper  sx={{bgcolor: '#F5F5DC', marginTop: '8px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px'}}>
        <Typography sx={{fontWeight: 'bold', textTransform: 'capitalize'  }} gutterBottom variant="h4">
        {params.category}
        </Typography> 
      </Paper>

    {categories.length > 0 &&
    categories.map(category=>(
      category.name === params.category &&
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Typography  sx={{ textAlign: 'center', width: '80%'  }} gutterBottom variant="subtitle1">
        {category.description}
      </Typography>
      </Box>
      
    ))
    }

        {products.filter(item => item.category.name === params.category).length < 1 &&
            <Box mx={2} mt={10}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">Sorry we currently do not have any {params.category} for now</Alert>
            </Stack>
            </Box>
        }
    {products.length > 0 &&
   <Grid container px={2} mt={2}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
   {products.map(product=>(
    product.category.name === params.category &&
    <Grid key={product.id} item xs={6} md={3}>
          
    <Card sx={{ display: "flex",  flexDirection: "column", height: "100%", borderRadius: '5px' }} elevation={1}>
    <Link onClick={()=> {dispatch(setProduct(product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
        <CardMedia
            component="img"
            sx={{ height: '150px' }}
            image={product.product_image}
            alt={product.name}
        />
        
        <CardContent  sx={{paddingX: '2px'}}>
        
         
        <Paper sx={{bgcolor: '#F5F5DC', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px'}}>
        <Typography sx={{fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2">
            {product.name}
        </Typography> 
      </Paper>
      <Box sx={{borderRadius: '5px'}}>
      <Typography variant='subtitle1' sx={{color: '#333333'}}>
        {truncateText(product.short_description, 40)}
      </Typography> 
      </Box>
                 

        </CardContent>

        
      </Link>
        <CardActions sx={{display: 'flex', bottom: 0, justifyContent: 'space-between', marginBottom: 0}}>
        <Box sx={{borderRadius: '5px', padding: '3px'}}>
            <Typography color="secondary" gutterBottom variant="h6" component="span">
          &#8358;{product.unit_price}
          </Typography>
          </Box>
          <Box>
          <Plate product={product} />
          </Box>
        </CardActions>    
    </Card>
</Grid>
   ))}
</Grid>
    }
    
</div>
  )
}

export default CategoryProducts
