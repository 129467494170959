import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Reviews from './Reviews';
export default function DetailsTab({product}) {
  
  const [value, setValue] = React.useState('Description');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', bgcolor: '#F5F5DC' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Product Details" 
          variant="scrollable"
          gap={1}
          scrollButtons={true}
          >
            <Tab label="Description" value="Description" />            
            <Tab label="Reviews" value="Reviews" />
            <Tab label="Specifications" value="Specifications" />
            
          </TabList>
        </Box>

        <TabPanel value="Description">{product.description}</TabPanel>
        <TabPanel value="Reviews"><Reviews/></TabPanel>
        <TabPanel value="Specifications">{product.nutritional_value}</TabPanel>
      
      </TabContext>
    </Box>
  );
}
