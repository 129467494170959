import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {Avatar} from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';


export default function Online() {
  const categories = useSelector((state) => state.products.categories);
  const items = useSelector((state) => state.plate.items);  
  const appMode = useSelector((state) => state.mode.mode);

  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/plate-content' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Badge badgeContent={items.length} color="error">
                      <Avatar src={appMode === 'dark' ? 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739184208/carticon-2-white_pm2cxu.png' : 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739019969/carticon-2-black_brx4js.png'} sx={{ padding: '5px', width: 30, height: 30}}>
                      </Avatar>
                  </Badge>
                  </ListItemIcon>
                  <ListItemText  primary={`Cart`} />
                  </ListItemButton>
              </ListItem>
            </Link>

                     
          </List>
          <Divider />
          <List>


            {/* list al categories here */}
            {categories.length > 0 &&
            
            categories.map(category=>(
              <Link key={category.id}  to={`/category/${category.name}`} component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src={category.image} sx={{ padding: '5px', bgcolor: '#e67409', width: 30, height: 30}}>
                  </Avatar>
                  </ListItemIcon>
                  <ListItemText  primary={category.name} />
                  </ListItemButton>
              </ListItem>
            </Link>
            ))
            }

            <Divider/>

            
          
            
            
            <Link  to='/dining' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <BookmarkBorderIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Orders`} />
                    </ListItemButton>
                </ListItem>
              </Link>

            {/* <Link  to='/referrals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText  primary={`Referral`} />
                    </ListItemButton>
                </ListItem>
              </Link> */}
            
              <Divider /> 

              <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Account`} />
                  </ListItemButton>
              </ListItem>
            </Link>
            

            {/* <Link  to='/wallet' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText  primary={`Wallet`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <SettingsIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}