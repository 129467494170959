import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

import { Link as RouterLink } from 'react-router-dom';
import { Link, Paper } from '@mui/material';
import { setFeatured } from '../Featured/featuredSlice';
import { useDispatch , useSelector} from 'react-redux';
import { setProduct } from '../Products/productSlice';

const NewUpdates = () => {

  const dispatch = useDispatch();
  const scrolltoTop = () => window.scrollTo(0, 0);
  const images = ['https://res.cloudinary.com/dnjauzgbv/image/upload/v1740691547/sellproductban_tii1hu.jpg',

    'https://res.cloudinary.com/dnjauzgbv/image/upload/v1740691547/banner2pro_e5kdql.jpg',
    'https://res.cloudinary.com/dnjauzgbv/image/upload/v1740691547/bannersecures_wkewl7.jpg',

  ];
  const featureds = useSelector((state) => state.featured.featureds);

    const settings = {
        autoplay: true,
        pauseOnHover: true,
        dots: false,
        infinite: true,
        autoplaySpeed: 7000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        vertical: true,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };


  return (
        <Paper elevation={1} sx={{bgcolor: '#F0E4CC', padding: '13px', marginTop: '-12px'}}>
          <Card sx={{height: 'fit-content',  borderRadius: '12px'}} elevation={0}>
          {images.length > 0 &&
          <Slider {...settings}>
          {images.map((image, index)=>(
        <Link key={index} to={`/product`} component={RouterLink} underline="none" color="inherit">
          <Box sx={{paddingBottom: '0px', height: '120px', borderRadius: '12px'}}>
          <img src={image} style={{height: '125px', width: '100%',  borderRadius: '12px'}} /> 
          </Box>
              
          </Link>
          ))}
          
        </Slider>
          }
      </Card>
      </Paper>
  )
}

export default NewUpdates
