import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DiningIcon from '@mui/icons-material/Dining';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightIcon from '@mui/icons-material/Nightlight';
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { switchMode } from '../components/Theme/modeSlice';
import ModeNight from '@mui/icons-material/ModeNight';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
const Search = styled('div')(({ theme }) => ({
  
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));


export default function Header() {
  const items = useSelector((state) => state.plate.items);
  const appMode = useSelector((state) => state.mode.mode);
  const notifications = useSelector((state) => state.notifications.notifications);

  const dispatch = useDispatch();

  
 

  const toggleSidebar = () => {
    const event = new Event("toggleSideBar");
    window.dispatchEvent(event);
  }

  return (
    <Box  sx={{ flexGrow: 1, position: 'fixed', zIndex: 1500, width: '100%' }}>
      <AppBar position="static" color='custom' enableColorOnDark={false}>
        <Toolbar>
          {appMode === 'dark' ? 
          <img style={{height: "40px"}} alt="Ez- Mart " src="https://res.cloudinary.com/dnjauzgbv/image/upload/v1740581785/ez-600-color-white_w2s0ym.png" />

          :
          <img style={{height: "40px"}} alt="Ez-Mart" src="https://res.cloudinary.com/dnjauzgbv/image/upload/v1740581785/ezmart-600-color-black_xklyee.png" />

          }
         
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
            
          {/* <IconButton
          onClick={()=>{
            if (appMode === 'dark') {
            dispatch(switchMode('light'))  
            }else{
                dispatch(switchMode('dark'))
            }
        }}
            size="large"
            aria-label="app mode"
            color="inherit"
          >

            {appMode === 'dark' ? <LightModeIcon />  : <ModeNight/>}
             
          </IconButton> */}


            <IconButton
            component={RouterLink} to="/plate-content"
              size="large"
              aria-label="show plates"
              color="inherit"
            >
              <Badge badgeContent={items.length} color="error">
              <Avatar src={appMode === 'dark' ? 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739184208/carticon-2-white_pm2cxu.png' : 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739019969/carticon-2-black_brx4js.png'} sx={{ padding: '5px', width: 30, height: 30}}>
                </Avatar>
              </Badge>
            </IconButton>

            <IconButton
              component={RouterLink} to="/notifications"
              size="large"
              aria-label="show notofocations"
              color="inherit"
            >
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>

            
          </Box>
          <IconButton
            onClick={toggleSidebar}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2, display: {md: 'none'} }}
          >
            <Avatar src={appMode === 'dark'? 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739184208/menu-white_l9kiu6.png' : 'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739156985/menu_4_tqeatg.png'}  sx={{ padding: '5px', width: 38, height: 38}}>
            </Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}