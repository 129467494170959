import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

import { Link as RouterLink } from 'react-router-dom';
import { CardContent, Divider, Link, Avatar } from '@mui/material';
import { setFeatured } from '../Featured/featuredSlice';
import { useDispatch , useSelector} from 'react-redux';
import { setProduct } from '../Products/productSlice';
import { green, pink } from '@mui/material/colors';

const CategorySlideOne = () => {
  const dispatch = useDispatch();
  const scrolltoTop = () => window.scrollTo(0, 0);
  const items = ["#e25e05"];
  const getRandomColor = () => items[Math.floor(Math.random() * items.length)];
  const categories = useSelector((state) => state.products.categories);
  
    const settings = {
        autoplay: true,
        pauseOnHover: true,
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 3,

              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 2,
              initialSlide: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          }
        ]
      };


  return (
        <Card sx={{paddingX: '10px', marginTop: '10px', marginBottom: '10px'}} elevation={0}>
        <Typography sx={{marginLeft: '15px', fontWeight: 'bold', textTransform: 'capitalize', fontSize: 20, textAlign: 'left'  }} gutterBottom variant="h6" component='div'>
            Categories
        </Typography>
          {categories.length > 0 &&
          <Slider {...settings}>

          {categories.map(category=>(
        <Link key={category.id}  to={`/category/${category.name}`} component={RouterLink} underline="none" color="inherit">
              <Box sx={{display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'center', borderRadius: '5px',  textDecoration: 'none', color: 'primary' }}>
               
               <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '5px', width: 60, height: 60, bgcolor: '#f06c0e', borderRadius: '6px', color: '#fff'}}>
              
               <Box>
               <Avatar sx={{height: '24px', color: '#444444', width: '24px'}} variant="rounded" src={category.image} >
               </Avatar>
               </Box>

               <Box>
                <Typography variant='subtitle2' sx={{textAlign: 'center', fontSize: '10px'}}>
                {category.name}
                </Typography>

               </Box>
                 
                  
               </Box>
                 
              </Box>
          </Link>
          ))}
          
        </Slider>
          }
      </Card>
  )
}

export default CategorySlideOne
