import { StarTwoTone } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: JSON.parse(localStorage.getItem('plate')) || [] 
}

export const plateSlice = createSlice({
    name: 'plate',
    initialState,
    reducers: {
        addItem: (state, action) =>{
            if (parseInt(action.payload.qty) < 1) {
                localStorage.setItem('notification', JSON.stringify({message : `We are sorry ${action.payload.name} has finished, please select something else.`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }else{
                if (!state.items.filter(item => item.id === action.payload.id).length > 0) {
                    state.items.push(action.payload);
                    localStorage.setItem('plate', JSON.stringify(state.items));
    
                    localStorage.setItem('notification', JSON.stringify({message : `${action.payload.name} added to cart`, 'type': 'success' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }else{
                    localStorage.setItem('notification', JSON.stringify({message : `${action.payload.name} is already in your cart!`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }
            }

            
            
        },
        removeItem: (state, action) => {
             state.items = state.items.filter(item => item.id !== action.payload.id);
             localStorage.setItem('plate', JSON.stringify(state.items));

            localStorage.setItem('notification', JSON.stringify({message : `${action.payload.name} removed from cart`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
        } ,

        emptyCart: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload.id);
            localStorage.setItem('plate', JSON.stringify(state.items));
       } ,
        
        setMealType: (state, action) => {
            let old_data = [];
            let productCurrent = [];
            state.items.forEach(product => {
                if (action.payload.id === product.id) {
                    let new_data = Object.assign(product, {meal_type: action.payload.meal});
                    old_data.push(new_data);
                    productCurrent = product;
                }else{
                    old_data.push(product) ;
                }
            });
            state.items= old_data;
            localStorage.setItem('plate', JSON.stringify(old_data));
            localStorage.setItem('notification', JSON.stringify({message : `${productCurrent.name} will be served for ${action.payload.meal}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
        },

        setQuantity: (state, action) => {
            let old_data = [];
            state.items.forEach(product => {
                if (action.payload.id === product.id) {
                    
                    if (parseInt(product.qty) < 1) {
                        localStorage.setItem('notification', JSON.stringify({message : `We are sorry ${product.name} has finished, please select something else.`, 'type': 'error' }));
                        const event = new Event('newMessage');
                        window.dispatchEvent(event);
                    }else{
                        if (parseInt(product.qty) < parseInt(action.payload.qty)) {
                            localStorage.setItem('notification', JSON.stringify({message : `Sorry only ${product.qty} quantity of ${product.name} is remaining`, 'type': 'error' }));
                            const event = new Event('newMessage');
                            window.dispatchEvent(event);
                            old_data.push(product);
                        }else{
                            let new_data = Object.assign(product, {quantity: parseInt(action.payload.qty)});
                            old_data.push(new_data);
                        }
                    }
                }else{
                    old_data.push(product) ;
                }
            });
            state.items= old_data;
            localStorage.setItem('plate', JSON.stringify(old_data));
        }
    }
})

export const {addItem, removeItem, setMealType, setQuantity, emptyCart} = plateSlice.actions;
export default plateSlice.reducer;