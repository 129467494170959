import React from 'react'
import PackageSlides from './PackageSlides';
import MobileMenu from './MobileMenu';
import TodayBreakFast from './TodayBreakFast';
import TodayLunch from './TodayLunch';
import TodayDinner from './TodayDinner';
import FoodPlans from '../Subscriptions/FoodPlans';
import SearchButton from '../Search/SearchButton';
import CategorySlideOne from '../Categories/CategorySlideOne';
import NewUpdates from '../Message/NewUpdates';
import MainCategory from './MainCategory';
import { Divider } from '@mui/material';
import SearchForm from '../Search/SearchForm';
import IconChips from './CompanyTags';

const Index = () => {
  return (
    <div style={{marginTop: '75px'}}>
      <NewUpdates/>
      <MainCategory/>
      
      {/* <SearchButton/> */}
      <SearchForm/>
      <IconChips/>
      <Divider/>
      <PackageSlides/>
      <TodayBreakFast/>
      {/* <CategorySlideOne/> */}
      <TodayLunch/>
    </div>
  )
}

export default Index
