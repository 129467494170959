import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import { Grid } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useDispatch } from 'react-redux';
import { setCategory } from '../Categories/categorySlice';
import { useSelector } from 'react-redux';

export default function MainCategory() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const category = useSelector((state) => state.category.currentCategory);
  const categories = useSelector((state) => state.products.categories);
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container px={2} sx={{marginTop: '1px'}} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={3}>
        <Box onClick={()=>{setOpen(true); dispatch(setCategory('Brand New'))}} sx={{display: 'flex', cursor: 'pointer', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dnjauzgbv/image/upload/v1740458984/shopping_zbm25x.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#F5F5DC'}}>
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px', fontWeight: 'bolder'}}>Brand New</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box onClick={()=>{setOpen(true); dispatch(setCategory('Fairly Used'))}} sx={{display: 'flex', cursor: 'pointer', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dnjauzgbv/image/upload/v1740458984/rebuild_abudcl.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#F5F5DC'}}>
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px', fontWeight: 'bolder'}}>Fairly Used</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box onClick={()=>{setOpen(true); dispatch(setCategory('Real Estate'))}} sx={{display: 'flex', cursor: 'pointer', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dnjauzgbv/image/upload/v1740458984/house_g1gdkz.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#F5F5DC'}}>
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px', fontWeight: 'bolder'}}>Real Estate</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box onClick={()=>{setOpen(true); dispatch(setCategory('Apartment/Lodge'))}} sx={{display: 'flex', cursor: 'pointer', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dnjauzgbv/image/upload/v1740458984/residential_xewjcl.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#F5F5DC'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px', fontWeight: 'bolder'}}>Apartment/Lodge</Typography>   
        </Box>
      </Grid>

      <Drawer
            sx={{zIndex: 60000}}
            anchor={'bottom'}
            open={open}
            onClose={()=>handleClose(open)}
          >
            <Box sx={{padding: '10px', height: '360px', overflow: 'auto', marginBottom: '20px'}}>

              <Box>
                <Typography variant='h4' sx={{textAlign: 'center'}}>
                  {category !== null && category}
                </Typography>
              </Box>
              <Divider/>
              {category !== null &&
              
              <List sx={{ width: '100%', maxWidth: 360 }}>
                  {categories.map(currentcat =>(
                    <Fragment key={currentcat.id}>
                    <ListItem onClick={()=>handleClose()} component={RouterLink} to={`/category/${currentcat.name}`} sx={{marginY: '2px', borderRadius: '5px', bgcolor: 'background.paper'}}>
                      <ListItemAvatar>
                        <Avatar sx={{bgcolor: '#ff6600'}} src={currentcat.image}>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText color='primary' primary={<Typography sx={{color: 'text.primary'}}>
                        {currentcat.name}
                      </Typography>} secondary={truncateText(currentcat.description, 120)} />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    </Fragment>
                  ))}
                  
                  
                  
                </List>
              }
                
            </Box>
          </Drawer>
    </Grid>
  );
}