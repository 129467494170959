import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import StarsIcon from '@mui/icons-material/Stars';

export default function IconChips() {
  return (
    <Stack direction="row" spacing={1} sx={{padding: '2px', justifyContent: 'center'}}>
      <Chip color="success" variant="outlined" icon={<VerifiedUserIcon />} label="Verified Products" />
      {/* <Chip color="error" icon={<FaceIcon />} label="No Scam Zone" variant="outlined" /> */}
      <Chip color="success" variant="outlined" icon={<StarsIcon />} label="Get Quality Without Breaking The Bank" />

    </Stack>
  );
}