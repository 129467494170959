
import React from 'react';
import { Box, Typography, Rating } from '@mui/material';

function Review({ review }) {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {review.title}
      </Typography>
      <Rating value={review.rating} readOnly />
      <Typography variant="body1" sx={{ mt: 1 }}>
        {review.review}
      </Typography>
    </Box>
  );
}

function Reviews() {
  const reviews = [
    { title: 'Amazing Product!', rating: 5, review: 'I\'m absolutely loving this product! The quality is top-notch and it\'s exactly what I needed. Highly recommend!' },
    { title: 'Great Value', rating: 4, review: 'I was a bit skeptical at first, but this product has really impressed me. The price is unbeatable for the quality you get.' },
    { title: 'Perfect for Me', rating: 5, review: 'I\'ve been searching for a product like this for ages, and I\'m so glad I found it. It\'s exactly what I needed and I couldn\'t be happier.' },
    { title: 'Good, but Not Great', rating: 3, review: 'This product is okay, but it\'s not the best I\'ve ever used. The quality is decent, but it\'s not worth the price in my opinion.' },
    { title: 'Excellent Customer Service', rating: 5, review: 'I had a issue with my order, but the customer service team was amazing. They helped me resolve the issue quickly and efficiently. I\'ll definitely be buying from this company again.' },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Reviews
      </Typography>
      {reviews.map((review, index) => (
        <Review key={index} review={review} />
      ))}
    </Box>
  );
}

export default Reviews;