import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Plate from '../Plates/Plate';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setProduct } from '../Products/productSlice';
import { useSelector } from 'react-redux';

const RelatedProducts = ({sample}) => {

    const scrolltoTop = () => window.scrollTo(0, 0);
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const truncateText = (text, maxLength) => {
      if (text.length <= maxLength) return text;
      return text.substring(0, maxLength) + "...";
    };
  return (
    <div style={{backgroundColor: '#F5F5DC', paddingBottom: '3px', marginBottom: '10px'}}>
    {/* <Typography mb={-5} ml={3} mt={2} sx={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: 20, textAlign: 'left'  }} gutterBottom variant="h6" component="div">
        Related Products
    </Typography> */}
    <Paper  sx={{bgcolor: '#F5F5DC'}}>
        <Typography className='fairly-used' sx={{fontWeight: 'bold', textTransform: 'capitalize'  }} gutterBottom variant="h4">
        Related Products
        </Typography> 
      </Paper>

    {products.length > 0 &&
    
    <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {products.map(product=>(
        parseInt(product.category_id) === parseInt(sample.category_id) &&

        <Grid key={product.id} item xs={6} md={3}>
          
        <Card sx={{ display: "flex",  flexDirection: "column", height: "100%", borderRadius: '5px', bgcolor: '#fff' }} elevation={2}>
        <Link onClick={()=> {dispatch(setProduct(product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia
                component="img"
                sx={{ height: '150px' }}
                image={product.product_image}
                alt={product.name}
            />
            
            <CardContent  sx={{paddingX: '2px'}}>
            
             
            <Paper elevation={1} sx={{bgcolor: '#F5F5DC', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px'}}>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2">
                {product.name}
            </Typography> 
          </Paper>
          <Box sx={{borderRadius: '5px'}}>
          <Typography variant='subtitle1' sx={{color: '#333333'}}>
            {truncateText(product.short_description, 40)}
          </Typography> 
          </Box>
                     

            </CardContent>

            
          </Link>
            <CardActions sx={{display: 'flex', bottom: 0, justifyContent: 'space-between', marginBottom: 0}}>
            <Box sx={{borderRadius: '5px', padding: '3px'}}>
                <Typography color="secondary" gutterBottom variant="h6" component="span">
              &#8358;{product.unit_price}
              </Typography>
              </Box>
              <Box>
              <Plate product={product} />
              </Box>
            </CardActions>    
        </Card>
    </Grid>
      ))}
</Grid>
    }
    
</div>
  )
}

export default RelatedProducts
