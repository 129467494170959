import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography, Card, CardMedia, Paper, CardActions, CardContent } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Plate from '../Plates/Plate';

export default function ProductImageSlider({ images, product }) {
    
  const [currentSlide, setCurrentSlide] = useState(1);
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowBackIos style={{ color: "black", fontSize: 30 }} />,
    nextArrow: <ArrowForwardIos style={{ color: "black", fontSize: 30 }} />,
    afterChange: (index) => setCurrentSlide(index + 1),
  };

  return (
    <Box>
    <Card sx={{marginTop: '0px', padding: '5px', borderRadius: '12px', marginBottom: '50px'}} elevation={1}>
      <Slider {...settings}>
        {images.map((img, index) => (
          <Box key={index} sx={{ textAlign: "center" }}>
            <Card sx={{borderRadius: '12px'}} elevation={1}>
            <CardMedia
                component="img"
                sx={{ width: '100%', height: 'auto', borderRadius: '12px' }}
                image={img} 
                alt={`Product ${index + 1}`} 
            />
            </Card>
          </Box>
        ))}
      </Slider>
      </Card>

      {/* Display Current Image / Total Images */}
      <Typography 
        variant="h6" 
        sx={{ 
        
          position: "absolute", 
          transform: 'translate(10px, -100px)',
          background: "#ff6600", 
          color: "white", 
          padding: "5px 10px", 
          borderRadius: 5 
        }}
      >
        {currentSlide} / {images.length}
      </Typography>

      <Card sx={{bgcolor: '#F5F5DC', marginTop: '-46px' }} elevation={0}>
                <CardContent sx={{padding: '3px'}}>
                <Paper elevation={1} sx={{bgcolor: '#F5F5DC', borderRadius: '5px',}}>
                  <Typography sx={{fontWeight: 'bold',  textTransform: 'capitalize'  }} gutterBottom variant="h6">
                      {product.name}
                  </Typography> 
                </Paper>

                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                  {/* <Box>
                  <Typography gutterBottom variant="h4" >
                      {product.name}
                  </Typography>
                  </Box> */}
                  {/* <Box>
                    <Stack mt={-1} sx={{ justifyContent: 'center', alignItems: 'center' }}  spacing={1}>
                      <Rating name="half-rating" size="small" defaultValue={4.5} precision={0.5} readOnly />
                    </Stack>
                    <Typography gutterBottom variant="subtitle2">
                      4.5
                    </Typography>
                  </Box> */}
                </Box>
                
                
                <Typography sx={{textAlign: 'left' }} gutterBottom variant="subtitle">
                {product.short_description}
                </Typography>

                </CardContent>
                <CardActions sx={{justifyContent: 'space-between', paddingX: '15px'}}>  

                <Typography mb={0} color="secondary" sx={{textAlign: 'left' }} gutterBottom variant="h4">
                &#8358;{product.unit_price}
                </Typography>

                <Plate product={product} />                
                </CardActions> 

          </Card>
    </Box>
  );
}
