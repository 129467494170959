import React, { useState, useEffect } from "react";
import { TextField, Typography, CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { useQuery } from 'react-query';
import apiClient from '../../request/http-common';
import axios from "axios";
import { debounce } from "lodash";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Card, Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProduct } from '../Products/productSlice';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';


export default function SearchForm() {
    const dispatch = useDispatch();

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + "...";
      };
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");

    const { isLoading: isLoadingplans, data } = useQuery(
        "search",
        async () => {
            return await apiClient.get(`/api/search/${debouncedSearch}`);
        },
        {
            enabled: !!debouncedSearch,
        }
    );

    // Debounce the search input to reduce API calls
    useEffect(() => {
        if (search.length > 0) {
            const searchContainer = document.getElementById('search-container');
            if (searchContainer) {
                searchContainer.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }
        const handler = debounce(() => setDebouncedSearch(search), 500);
        handler();
        return () => handler.cancel();
    }, [search]);


    return (
        <div>

                <Box id="search-container" sx={{
                    
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: 'white',
                    marginTop: '5px',
                    marginBottom: '5px',
                    paddingX: '2px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>


                <Card elevation={1} sx={{width: '70%'}}>
                <Paper
                elevation={0}
                component="form"
                sx={{ p: 0, border: '3px solid #F5F5DC', borderRadius: '8px', display: 'flex', alignItems: 'center', width: '100%' }}
                >
                
                <InputBase
                sx={{ flex: 1, paddingLeft: '10px'}}
                placeholder="Search EZ-MART"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="secondary" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
                </IconButton>
                </Paper>
                </Card>

                <Button  startIcon={<MonetizationOnIcon />} variant="contained" sx={{ fontWeight: 'bolder', paddingX: '5px', borderRadius: '6px', paddingX: '10px', bgcolor: '#F5F5DC', color: '#e35205'}} size='small'>
                Sell Items
                </Button>
                </Box>

            {isLoadingplans && <CircularProgress style={{ margin: "20px auto", display: "block" }} />}
                {search !== ''&&
            <List sx={{padding: '10px'}}>
                {/* Products Section */}
                {data?.data?.products?.length > 0 && 

                    data?.data?.products?.map((item) => (
                   
                    <ListItem  onClick={()=> {dispatch(setProduct(item))}} to={`/product`} component={RouterLink}  key={`product-${item.id}`} alignItems="flex-start" disablePadding>
                         <ListItemAvatar>
                        <Avatar variant="square" alt={item.name} src={item.product_image} />
                        </ListItemAvatar>
                        <ListItemText
                        primary="Products"
                        secondary={
                            <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ color: '#ff6600', display: 'inline' }}
                            >
                               { item.name }
                            </Typography>
                             - {truncateText(item.description, 50)}
                            </React.Fragment>
                        }
                        />
                    </ListItem>

                ))}

                    {data?.data?.categories?.length > 0 && 

                    data?.data?.categories?.map((item) => (

                    <ListItem component={RouterLink} to={`/category/${item.name}`} key={`product-${item.id}`} alignItems="flex-start" disablePadding>
                         <ListItemAvatar>
                        <Avatar sx={{bgcolor: '#e35205'}} variant="square" alt={item.name} src={item.image} />
                        </ListItemAvatar>
                        <ListItemText
                        primary="Categories"
                        secondary={
                            <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ color: '#ff6600', display: 'inline' }}
                            >
                            {item.name}
                            </Typography>
                            - {truncateText(item.description, 50)}
                            </React.Fragment>
                        }
                        />
                    </ListItem>

                    ))}
                
            </List>}
        </div>
    );
}
